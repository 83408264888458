import React from 'react'
import {graphql} from 'gatsby'

import Layout from '../../components/layout'
import Exterior from '../../components/products/Exterior'
import Breadcrumbs from '../../components/sections/Breadcrumbs';

export default ({data}) => {
    const product = data.product
    return (
      <Layout>
        <Breadcrumbs />
        <Exterior product={product} />
      </Layout>
    )
}

export const query = graphql`
query($slug: String!) {
    product: wpProduct(slug: {eq: $slug}) {
      title
      exteriorProduct {
        options {
          productOption {
            sizeLengthSpec
            profileoption
            price
            dimensions
            description
            colours {
              colour
            }
            imageGallery {
              sourceUrl
              altText
            }
          }
        }
      }
      relatedProducts {
        product4 {
          ... on WpProduct {
            title
            slug
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    fluid(fit: CONTAIN, maxWidth: 300, quality: 50) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
        product3 {
          ... on WpProduct {
            title
            slug
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    fluid(fit: CONTAIN, maxWidth: 300, quality: 50) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
        product2 {
          ... on WpProduct {
            title
            slug
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    fluid(fit: CONTAIN, maxWidth: 300, quality: 50) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
        product1 {
          ... on WpProduct {
            title
            slug
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    fluid(fit: CONTAIN, maxWidth: 300, quality: 50) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
        showRelatedProducts
      }
    }
}
`