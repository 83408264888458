import React from 'react'

class ExteriorCheckboxes extends React.Component {
    toggle(id) {
        this.props.toggle(id)
    }
    checkColoursExists() {
        if (this.props.options) {
            return (
                <div>
                    <span className="text-lg md:text-xl mb-4 block">{this.props.title}</span>
                    {this.props.options.map((option, i) => (
                        <div className="w-full border-solid border border-gray-300 hover:border-primary rounded mb-4 px-5 py-4" key={i}>
                            <div className="flex items-center">
                                <input defaultChecked={this.props.checkbox === i ? true : false } name="colour" onClick={e=>this.toggle(e.target.id)} id={i} type="radio" /> 
                                <p className="checkChange mb-0 pl-4"><b>{option.profileoption}</b></p>
                            </div>
                            <p className="checkChange mb-0 pl-8">
                                {option.colours && <span><b>Colours:</b> {option.colours.map((colour) => (`${colour.colour}`))}</span>}
                            </p>
                            <p className="checkChange mb-0 pl-8">
                              {option.dimensions && <span><b>Size:</b> {option.dimensions}</span>}
                            </p>
                        </div>
                    ))}
                </div>
            )
        } else {
            return (
                <div></div>
            )
        }
   }
    render() {
        return (
            this.checkColoursExists()
        )
    }
}

export default ExteriorCheckboxes
