import React from 'react'

import TabbedInfo from './utilites/tab'
import ExteriorCheckboxes from './utilites/checkbox-exterior'
import ImageGallery from './utilites/product-images'
import MainInfo from './utilites/mainspecs'
import ProductItem from './ProductListingItem'
import Cta from '../sections/Cta'
import Emailer from '../sections/Emailer'


class Exterior extends React.Component  {
    constructor(props) {
        super(props)

        const options = props.product.exteriorProduct.options.productOption
        const cur = 0

        const productOverrides = props.overrides === null ? null : props.overrides
       
        this.state = { 
            productOverrides: props.overrides === null ? null : props.overrides,
            product: props.product,
            option: options[cur],
            title: options[cur].profileoption,
            mainImage: {
                url: options[cur].imageGallery[0].sourceUrl,
                alt: options[cur].imageGallery[0].altText,
            },
            thumbnails: options[cur].imageGallery,
            price: null,
            dimensions: options[cur].dimensions,
            options: options,
            desc: options[cur].description,
            spec: options[cur].sizeLengthSpec,
            checkbox: 0,
            related: [
                props.product.relatedProducts.product1,
                props.product.relatedProducts.product2,
                props.product.relatedProducts.product3,
                props.product.relatedProducts.product4,
            ],
            showRelated: props.product.relatedProducts.showRelatedProducts
        }
        
    }

    toggleOptions = (id) => {
        const option = this.state.options[id]
        this.setState({
            option: option,
            title: option.profileoption,
            mainImage: {
                url: option.imageGallery[0].sourceUrl,
                alt: option.imageGallery[0].altText,
            },
            thumbnails: option.imageGallery,
            dimensions: option.dimensions,
            desc: option.description,
            spec: option.sizeLengthSpec,
            checkbox: id,
        })
    }
    
    updateImage = (id) => {
        this.setState({
            mainImage: {
                url: this.state.option.imageGallery[id].sourceUrl,
                alt: this.state.option.imageGallery[id].altText,
            },
        })
    }

    relatedEmpty() {
        let empty = this.state.related.filter(item => item )
        return empty.length !== 0 ? true : false
    }

    render() {
        
        return (
            <div className="container mx-auto">
                <div className="mb-16 md:mb-24">
                    <div className="flex flex-wrap">
                        <div className="w-full md:w-1/2 md:pr-12 lg:pr-24">
                            <ImageGallery 
                                toggle={this.updateImage}
                                thumbnails={this.state.thumbnails}
                                mainImageUrl={this.state.mainImage.url}
                                mainImageAlt={this.state.mainImage.alt} 
                            />                        
                        </div>
                        <div className="w-full md:w-1/2 md:pl-8">
                            {this.state.title && <h1 className="text-4xl leading-normal">{this.state.title}</h1>}
                            <MainInfo price={this.state.price} dimensions={this.state.dimensions}/>
                            <ExteriorCheckboxes title="Profile/Option:" toggle={this.toggleOptions} options={this.state.options} checkbox={this.state.checkbox}/>
                            <div className="product__info">
                                <TabbedInfo desc={this.state.desc} info={this.state.spec} />
                            </div>
                        </div>
                    </div>
                </div>   
                {(this.relatedEmpty() && this.state.showRelated) && <div>
                    <h3 className="px-4 text-3xl mb-8 text-center">Related Products</h3>
                    <div className="flex flex-wrap  mb-8 md:mb-16">
                        {this.state.related.map((product, i) =>  (
                             product && <ProductItem key={i} product={product} />
                        ))}
                    </div>
                </div>}
                <Cta />
                <Emailer />
            </div>      
        )
    }
}

export default Exterior